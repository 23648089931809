import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { FeathersService } from '../services/feathers.service';
import { UserModel } from '../models/user';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user: UserModel;

  constructor(public feathersService: FeathersService, public router: Router) {
    this.user = this.feathersService.authenticatedUser;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.feathersService.authenticateGuard()).pipe(
      switchMap(x => {
        if (!x) {
          return this.router.navigate(['/login', { returnUrl: state.url.toString() }]);
        }
        if (state.url === '/' && this.user.administrator.permissions === 'global_admin'){
          return this.router.navigate(['/companies']);
        }
        if (state.url === '/' && this.user.administrator.permissions === 'company_admin'){
          return this.router.navigate(['/brands']);
        }
        return of(true);
      })
    );
  }
}

// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { Observable, of } from 'rxjs';
// import { FeathersService } from '../services/feathers.service';
//
// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGuard implements CanActivate {
//   isAuthenticated: Promise<boolean>;
//
//   constructor(public feathersService: FeathersService, public router: Router) {
//     this.isAuthenticated = this.feathersService.authenticateGuard();
//   }
//
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//     this.isAuthenticated = this.feathersService.authenticateGuard();
//     return this.isAuthenticated.then((authenticated) => {
//       console.log(authenticated);
//       if (!authenticated) {
//         this.router.navigate(['/login', { returnUrl: state.url.toString() }]);
//       }
//       return authenticated;
//     });
//   }
// }
