import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {UserRole} from './models/enums';
import {PermisionGuard} from './guards/permision.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main-content.module').then(m => m.MainContentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./main/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./main/private/pages/brands/brands.module').then(m => m.BrandsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'models',
    loadChildren: () => import('./main/private/pages/models/models.module').then(m => m.ModelsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'editions',
    loadChildren: () => import('./main/private/pages/editions/editions.module').then(m => m.EditionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'items',
    loadChildren: () => import('./main/private/pages/items/items.module').then(m => m.ItemsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('./main/private/pages/analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [AuthGuard, PermisionGuard],
    data: {
      enabledRoles: UserRole.GlobalAdmin
    }
  },
  {
    path: 'certificates',
    loadChildren: () => import('./main/private/pages/certificates/certificates.module').then(m => m.CertificatesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./main/private/pages/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, PermisionGuard],
    data: {
      enabledRoles: UserRole.GlobalAdmin
    }
  },
  {
    path: 'companies',
    loadChildren: () => import('./main/private/pages/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuard, PermisionGuard],
    data: {
      enabledRoles: UserRole.GlobalAdmin
    }
  },
  {
    path: '**',
    loadChildren: () => import('./main/404/not-found.module').then(m => m.NotFoundModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
