<form id="create-edition" [formGroup]="createEditionForm">
  <div *ngIf="data.type === 'create'" class="modal-title">Add new edition</div>
  <div *ngIf="data.type === 'edit'" class="modal-title">{{data.editionCard.name}}</div>
  <div class="field-item half-part">
    <span class="title-disable">Brand/ Author</span>
    <input class="not-active" formControlName="brandAuthor" [readOnly]="true" type="text">
  </div>
  <div class="field-item half-part">
    <span class="title-disable">Product model</span>
    <input class="not-active" formControlName="productModel" [readOnly]="true" type="text">
  </div>
  <div class="field-item half-part">
    <span class="required">Edition name</span>
    <input [ngClass]="{'is-invalid': createEditionForm.get('name')?.invalid && createEditionForm.get('name')?.touched}"
           formControlName="name" type="text">
  </div>
  <div class="field-item half-part">
    <span class="required">Limited edition</span>
    <div class="field-slide">
      <mat-slide-toggle color="none" class="slider" formControlName="limited" [checked]="isLimitedEdition"
                        (input)="isLimitedEdition = $event.target['checked']">
      </mat-slide-toggle>
      <input type="number" min="1" max="80" formControlName="quantity" placeholder="Quantity"
             *ngIf="isLimitedEdition && createEditionForm.get('quantity')"
             [ngClass]="{'is-invalid': createEditionForm.get('limited').value === true &&
             createEditionForm.get('quantity').touched &&
             createEditionForm.get('quantity').invalid}">
    </div>
  </div>
  <div class="field-item">
    <span>Description</span>
    <input formControlName="description" type="text">
  </div>
  <div class="field-item">
    <span>Notes</span>
    <textarea formControlName="notes" type="text" rows="2"></textarea>
  </div>
  <div class="title" formArrayName="attributes"
       *ngFor="let attribute of createEditionForm.get('attributes')['controls'], index as i">
    <div class="title-new" [formGroupName]="i">
      <div class="title-block">
        <div class="field-item half-part">
          <span class="required">Title</span>
          <input
            [ngClass]="{'is-invalid': createEditionForm.get('attributes.' + i + '.title').invalid && createEditionForm.get('attributes.' + i + '.title')?.touched}"
            formControlName="title" class="form-input" type="text">
        </div>
        <span>-</span>
        <div class="field-item with-trash">
          <div class="field-item">
            <span class="required">{{createEditionForm.get('attributes.' + i + '.link').value ? 'Link' : 'Value'}}</span>
            <input
              [ngClass]="{'is-invalid':
              createEditionForm.get('attributes.' + i + '.value').invalid && createEditionForm.get('attributes.' + i + '.value')?.touched}
              "
              formControlName="value" class="form-input" type="text">
          </div>
        </div>
      </div>
      <button *ngIf="i > 0" (click)="removeTitle(i)"></button>
      <div class="action-box" >
        <div class="checkbox">
          <label class="custom-checkbox">Make this field link
            <input (change)="changeValidator(i)" formControlName="link" type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="checkbox second">
          <label class="custom-checkbox">Important field
            <input formControlName="important" type="checkbox" checked="checked">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-add">
    <button class="item-plus" (click)="addTitle()">Add field</button>
  </div>
  <div class="drag-zone-many">
    <div>
      <span class="drag-title">Drag & drop Item images</span>
      <span class="drag-title">({{imagesCount}})</span>
      <span class="drag-formats">*.png .jpg .jpeg</span>
    </div>
    <div>
      <div class="drop-img">
        <div class="drop-area"
             [ngClass]="{'file-incorrect': createEditionForm.get('images').touched && !createEditionForm.get('images').value.length}">
          <span [innerHTML]="icons.icons.upload | safeHTML"></span>
          <input id="fileElem" max="2048" type="file" (input)="setImages($event)" multiple
                 accept="image/x-png,image/jpeg">
        </div>
        <div class="preview" *ngFor="let image of images, index as i">
          <img [src]="image.src ? image?.src : baseUrl + 'files/' + image.file.filename" alt="/">
          <button (click)="removeImage(i)"></button>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="data.type === 'create'" class="modal-actions">
    <button (click)="createEdition()" class="btn-save" mat-raised-button>Save</button>
    <button (click)="dialogRef.close()" class="btn-cancel">Cancel</button>
  </div>
  <div *ngIf="data.type === 'edit'" class="modal-actions">
    <button (click)="editEdition()" class="btn-save" mat-raised-button>Edit</button>
    <button (click)="dialogRef.close()" class="btn-cancel">Cancel</button>
  </div>
</form>
