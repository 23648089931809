import {Component, Inject} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FeathersService} from '../../../../services/feathers.service';
import {AlertMessageComponent} from '../../components/alert-message/alert-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent {
  addCertificate: FormGroup;
  status: string = '';
  constructor(public dialogRef: MatDialogRef<AddCertificateComponent>,
              private featherService: FeathersService,
              private _snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  addCertificates(id): void {
    this.featherService.generateCertificate(id).then((result: any) => {
      this.showSnackBar('success');
      this.status = 'not-empty';
      this.dialogRef.close();
    }).catch((error) => {
      this.showSnackBar('error-certificate');
    });
  }

  private showSnackBar(status): void {
    this._snackBar.openFromComponent(AlertMessageComponent, {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {status, type: 'certificate'}
    });
  }
}
