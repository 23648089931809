import { Injectable } from '@angular/core';
import {UserRole} from '../models/enums';
import {FeathersService} from './feathers.service';


@Injectable()
export class PermissionService {
  private readonly roles = [UserRole.GlobalAdmin, UserRole.Admin];

  constructor(private feathersService: FeathersService) {}

  public hasAccess(enabled: UserRole | UserRole[]): boolean {
    if (enabled) {
      return this.hasAccessByEnabled(enabled);
    } else {
      return true;
    }
  }

  public hasAccessByEnabled(enabled: UserRole | UserRole[]): boolean {
    enabled = this._toArray(enabled);
    const currentRole = this._toArray(this.feathersService.authenticatedUser.administrator.permissions);
    return enabled.some(x => currentRole.includes(x));
  }

  private _toArray(value: UserRole | UserRole[]): UserRole[] {
    return value instanceof Array ? value : [value];
  }
}
