import {Component, HostBinding, Inject, OnDestroy} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AlertMessageComponent} from '../../components/alert-message/alert-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {FeathersService} from '../../../../services/feathers.service';
import {ActivatedRoute} from '@angular/router';
import {EditionsModel} from '../../../../models/edition';
import {IconsService} from '../../../../services/icons.service';
import {FilesService} from '../../../../services/files.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-created-edition',
  templateUrl: './created-edition.component.html',
  styleUrls: ['./created-edition.component.scss']
})
export class CreatedEditionComponent implements OnDestroy {
  preload = new FormData();
  isLimitedEdition = false;
  createEditionForm: FormGroup;
  imagesCount: number = 0;
  deletedPhotos: string[] = [];
  baseUrl: string = environment.url;
  images = [];
  havePhoto: boolean = false;
  loadedPhoto: number;
  private subscription = new Subscription();
  files: any[];

  constructor(public dialogRef: MatDialogRef<CreatedEditionComponent>,
              private _snackBar: MatSnackBar,
              public icons: IconsService,
              private filesService: FilesService,
              private featherService: FeathersService,
              private router: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.initEditionForm();
    this.router.queryParams.subscribe(
      (queryParam) => {
        this.createEditionForm.patchValue(queryParam);
      });
    this.initFormsNamesAndType();
  }

  createEdition(): void {
    this.createEditionForm.markAllAsTouched();
    if (this.createEditionForm.valid) {
      Object.values(this.createEditionForm.get('images').value).forEach((el: any) => {
        const refactoredName = el.name.replace(/[^A-Z0-9.]+/ig, '-');
        this.preload.append('files', el, refactoredName);
      });
      this.filesService.saveImages(this.preload).subscribe(files => {
        const imagesId = files.map(file => file._id);
        this.createEditionForm.get('images').setValue(imagesId);
        const addItemStream$ = this.featherService.feathers.service('editions').create(this.createEditionForm.value).then((result: EditionsModel) => {
          this.showSnackBar('success');
          this.dialogRef.close();
        }).catch((error) => {
          this.showSnackBar('error');
        });
        this.subscription.add(addItemStream$);
      });
    } else {
      this.showSnackBar('error');
    }
  }

  changeValidator(index?: number): void {
    const x = (this.createEditionForm.get('attributes') as FormArray).at(index).get('link');
    if (x.value) {
      const URL_REGEX = /^(http|https):\/\//i;
      (this.createEditionForm.get('attributes') as FormArray).at(index).get('value').setValidators([Validators.pattern(URL_REGEX)]);
      (this.createEditionForm.get('attributes') as FormArray).at(index).get('value').updateValueAndValidity();
    } else {
      (this.createEditionForm.get('attributes') as FormArray).at(index).get('value').clearValidators();
      (this.createEditionForm.get('attributes') as FormArray).at(index).get('value').updateValueAndValidity();
    }
  }

  addTitle(): void {
    (this.createEditionForm.get('attributes') as FormArray).push(new FormGroup({
      title: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      link: new FormControl(false),
      important: new FormControl(false),
    }));
  }

  removeTitle(index: number): void {
    (this.createEditionForm.get('attributes') as FormArray).removeAt(index);
  }

  setImages(event: any): void {
    if ((this.data.type === 'edit' && this.havePhoto) || this.data.type === 'create') {
      this.files = event.target.files;
    }
    if (this.data.type === 'edit' && !this.havePhoto) {
      this.files = event;
      this.loadedPhoto = event.length;
    }
    if (this.data.type === 'create'){
      this.havePhoto = true;
      this.files = event.target.files;
    }
    let existingFiles = [];
    if (this.havePhoto) {
      const result = Array.from(this.files).filter((file: File) => ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type));
      if (this.imagesCount < 21 && this.imagesCount + result.length < 21) {
        this.imagesCount = this.imagesCount + result.length;
        if (this.createEditionForm.get('images').value) {
          existingFiles = this.createEditionForm.get('images').value;
        }
        this.createEditionForm.setControl('images', new FormControl());
        this.createEditionForm.get('images').setValue([...existingFiles, ...result]);
        Object.keys(result).forEach(key => {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            this.images.push({src: e.target.result});
          };
          reader.readAsDataURL(result[key]);
        });
      } else {
        this.showSnackBar('error-add-photo');
      }
    } else {
      const result = this.files;
      if (this.imagesCount < 21 && this.imagesCount + result.length < 21){
        this.imagesCount = result.length;
        this.images = result;
      }
    }
    this.havePhoto = true;
  }

  removeImage(i: number): void {
    const fileArray = Array.from(this.createEditionForm.get('images').value);
    if (this.images[i]._id) {
      this.deletedPhotos.push(this.images[i]._id);
      this.loadedPhoto = this.loadedPhoto - 1;
    }
    fileArray.splice(i, 1);
    this.images.splice(i, 1);
    this.createEditionForm.get('images').setValue(fileArray);
    this.imagesCount = this.imagesCount - 1;
  }

  private initEditionForm(): void {
    this.createEditionForm = new FormGroup({
      brandAuthor: new FormControl(),
      productModel: new FormControl(),
      name: new FormControl('', [Validators.required]),
      limited: new FormControl(false),
      description: new FormControl(),
      notes: new FormControl(),
      attributes: new FormArray([]),
      images: new FormControl('', [Validators.required]),
      company: new FormControl(),
      brand: new FormControl(),
      model: new FormControl()
    });

    const limitedControlStream$ = this.createEditionForm.get('limited').valueChanges.subscribe((value) => {
      if (value) {
        this.createEditionForm.addControl('quantity', new FormControl('', Validators.required));
      } else {
        this.createEditionForm.removeControl('quantity');
      }
    });
    this.subscription.add(limitedControlStream$);
  }

  private showSnackBar(status): void {
    this._snackBar.openFromComponent(AlertMessageComponent, {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {status, type: 'Edition'}
    });
  }

  private initFormsNamesAndType(): void {
    this.featherService.getName('brands', this.createEditionForm.value.brand).then(value => {
      this.createEditionForm.get('brandAuthor').patchValue(value.data[0].name);
    });
    this.featherService.getName('models', this.createEditionForm.value.model).then(value => {
      this.createEditionForm.get('productModel').patchValue(value.data[0].name);
    });
    this.createEditionForm.get('limited').patchValue(false);
    if (this.data.type === 'edit') {
      this.setValuesForEdit();
    } else {
      this.addTitle();
    }
  }

  editEdition(): void {
    this.createEditionForm.markAllAsTouched();
    if (this.createEditionForm.valid) {
      if (this.loadedPhoto > 0) {
        if (this.deletedPhotos.length) {
          this.deleteImage(this.deletedPhotos);
        }
      }
      const fileObjects = Object.values(this.createEditionForm.get('images').value)
        .filter((file: any) => file instanceof File);
      if (fileObjects.length){
        fileObjects.forEach((el: any) => {
          const refactoredName = el.name.replace(/[^A-Z0-9.]+/ig, '-');
          this.preload.append('files', el, refactoredName);
        });
        this.filesService.saveImages(this.preload).subscribe(files => {
          const imagesId = files.map(file => file._id);
          const existingId = Object.values(this.createEditionForm.get('images').value).filter((file: any) => typeof file === 'string');
          this.createEditionForm.get('images').setValue([...existingId, ...imagesId]);
          this.editedValues();
        });
      } else {
        this.editedValues();
      }
    } else {
      this.showSnackBar('error');
    }
  }

  deleteImage(image): void {
    this.filesService.deleteImage(image).subscribe();
  }

  private editedValues(): void {
    this.featherService.changeEdition(this.data.editionCard._id, this.createEditionForm.value).then(result => {
      this.showSnackBar('success-edit');
      this.dialogRef.close();
    });
  }

  private setValuesForEdit(): void {
    this.isLimitedEdition = this.data.editionCard.limited;
    this.data.editionCard.attributes.forEach(result => {
      this.addTitle();
    });
    this.createEditionForm.patchValue(this.data.editionCard);
    this.featherService.getImages(this.data.editionCard.images).then(({data: files}) => {
      this.data.editionCard.previewImages = files.map(el => {
        return el;
      });
      this.setImages(this.data.editionCard.previewImages);
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
