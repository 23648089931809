import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FeathersService } from '../services/feathers.service';
import { UserModel } from '../models/user';
import {UserRole} from '../models/enums';
import {PermissionService} from '../services/permission.service';


@Injectable({
  providedIn: 'root'
})
export class PermisionGuard implements CanActivate {
  user: UserModel;


  constructor(public feathersService: FeathersService, public router: Router, private permissionService : PermissionService) {
    this.user = this.feathersService.authenticatedUser;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const enabledRoles = route.data.enabledRoles as UserRole | UserRole[];

    if (this.permissionService.hasAccess(  enabledRoles )) {
      return true;
    } else {
      this.router.navigate(['/404']);
      return false;
    }
  }
}
