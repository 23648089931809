import {Injectable} from '@angular/core';

import * as feathersRx from 'feathers-reactive';
import * as io from 'socket.io-client';
import * as feathers from '@feathersjs/feathers';

import feathersSocketIOClient from '@feathersjs/socketio-client';
import feathersAuthClient from '@feathersjs/authentication-client';
import {UserModel} from '../models/user';
import {query} from '@angular/animations';

@Injectable({providedIn: 'root'})
export class FeathersService {
  feathers = feathers();

  private socket = io('https://lingon.nit-stage.lionwood.software/', {transports: ['websocket']});

  constructor() {
    this.feathers
      .configure(feathersSocketIOClient(this.socket))
      .configure(feathersAuthClient({
        storageKey: 'feathers-jwt',
        jwtStrategy: 'jwt',
        storage: window.localStorage
      }))
      .configure(feathersRx({
        idField: '_id'
      }));
    this.feathers.authentication.options.path = 'authentication-administrators';
  }

  async getCompanies(val): Promise<any> {
    return this.feathers.service('companies').find({
      query: {
        ...(val.searchValue.length ? {name: {$search: val.searchValue}} : null),
        $limit: 50,
        $skip: val.skip,
        $sort: {
          [val.sortValue.name]: val.sortValue.value,
        }
      }
    });
  }

  async getCertificates(id, val?): Promise<any> {
    return this.feathers.service('certificates-administrator').find({
      query: {
        ...(val?.searchValue.length ? {token: {$search: val?.searchValue}} : null),
        $limit: 50,
        $skip: val?.skip,
        ...(id ? {item: id} : null),
        $sort: {
          [val?.sortValue.name + '.name']: val?.sortValue.value,
        }
      }
    });
  }

  async getCertificate(id: string[]): Promise<any> {
    return this.feathers.service('certificates-administrator').find({
      query: {
        _id: {$in: id},
        $limit: 50,
      }
    });
  }

  async getBrands(companyId, val): Promise<any> {
    return this.feathers.service('brands').find({
      query: {
        ...(val.searchValue.length ? {name: {$search: val.searchValue}} : null),
        $limit: 50,
        ...(val.skip !== 0 ? {$skip : val.skip } : null),
        'company._id': companyId,
        $sort: {
          [val.sortValue.name]: val.sortValue.value,
        }
      }
    });
  }

  async changeBrand(brandId: string, data): Promise<any> {
    return this.feathers.service('brands').patch(brandId, data);
  }

  async changeModel(modelId: string, data): Promise<any> {
    return this.feathers.service('models').patch(modelId, data);
  }

  async changeCompany(companyId: string, data): Promise<any> {
    return this.feathers.service('companies').patch(companyId, data);
  }

  async changeItem(itemId: string, data): Promise<any> {
    return this.feathers.service('items').patch(itemId, {description: data});
  }

  async changeEdition(editionId: string, data): Promise<any> {
    return this.feathers.service('editions').patch(editionId, data);
  }


  async getName(location: string, ids: string | string[]): Promise<any> {
    if (!(ids instanceof Array)) {
      ids = [ids];
    }
    return this.feathers.service(`${location}`).find({
      query: {
        _id: {$in: ids},
        $select: ['name', 'username']
      }
    });
  }

  async generateCertificate(id): Promise<any> {
      return this.feathers.service('certificates-generate').create({item : id});
  }

  async getUsers(val): Promise<any> {
    return this.feathers.service('administrators').find({
      query: {
        $or: [
          {...(val.searchValue.length ? {username: {$search: val.searchValue}} : null)},
          {...(val.searchValue.length ? {email: {$search: val.searchValue}} : null)},
        ],
        $skip: val.skip,
        $limit: 50,
        $sort: {
          [val.sortValue.name]: val.sortValue.value,
        }
      }
    });
  }

  async getUsersForCompany(val: string[] | string): Promise<any> {
    if (!(val instanceof Array)) {
      val = [val];
    }
    return this.feathers.service('administrators').find({
      query: {
        $limit: 100,
        company: {$in: val},
      paginate: false,
      },
    });
  }

  async assignCompany(userId: string, data: string): Promise<any> {
    return this.feathers.service('administrators').patch(userId, data);
  }

  async changeUserStatus(userId: string, status): Promise<any> {
    return this.feathers.service('administrators').patch(userId, {status});
  }

  async getImages(images: string[]): Promise<any> {
    return this.feathers.service('images').find({
      query: {
        _id: {$in: images},
        $limit: 50,
      }
    });
  }

  async getModels(brandId, val): Promise<any> {
    return this.feathers.service('models').find({
      query: {
        ...(val.searchValue.length ? {name: {$search: val.searchValue}} : null),
        $limit: 50,
        $skip: val.skip,
        'brand._id': brandId,
        $sort: {
          [val.sortValue.name ]: val.sortValue.value,
        }
      }
    });
  }

  async getEditions(modelId, val?): Promise<any> {
    return this.feathers.service('editions').find({
      query: {
        ...(val.searchValue.length ? {name: {$search: val.searchValue}} : null),
        $limit: 50,
        $skip: val.skip,
        'model._id': modelId,
        $sort: {
          [val.sortValue.name]: val.sortValue.value,
        }
      }
    });
  }

  async getEditionForUpdateCounts(id): Promise<any> {
    return this.feathers.service('editions').get(id);
  }

  async getItems(editionId, val): Promise<any> {
    return this.feathers.service('items').find({
      query: {
        ...(val.searchValue.length ? {name: {$search: val.searchValue}} : null),
        $limit: 50,
        $skip: val.skip,
        'edition._id': editionId,
        $sort: {
          [val.sortValue.name]: val.sortValue.value,
        }
      }
    });
  }

  userLogin = async (credentials) => {
    try {
      return await this.feathers.reAuthenticate();
    } catch (error) {
      return await this.feathers.authenticate(credentials);
    }
  }

  authenticateGuard(): Promise<boolean> {
    return new Promise((res, rej) => {
      this.feathers.authenticate().then((user: any) => res(true)).catch((e) => res(false));
    });
  }

  get authenticatedUser(): UserModel {
    return JSON.parse(localStorage.getItem('user'));
  }

  logout(): any {
    return this.feathers.logout();
  }
}
