<form id="edition-popup" >
  <swiper class="swiper-img" [slidesPerView]="1" [spaceBetween]="50" [navigation]="true"  [pagination]="{ clickable: true }">
    <ng-template swiperSlide *ngFor="let image of sliderImages"><img [src]="baseUrl + 'files/' + image" ></ng-template>
  </swiper>
  <div class="content">
    <button class="edit" *ngIf="!data.card.locked && userRole === 'company_admin'" (click)="openEditModalDialog($event, data.card)"></button>
    <div class="crumbs">
      <span>{{data.card.brand.name}}</span>
      <span>{{data.card.model.name}}</span>
    </div>
    <div class="title">
      <span class="title-name">{{data.card.name}} </span>
      <span *ngIf="data.card.limited" class="count">{{data.card.counts.certificates}}/{{data.card.quantity}} certificates</span>
      <span *ngIf="!data.card.limited" class="unlimited">Unlimited</span>
    </div>
    <div class="data">
      <div class="data-items">
        <span class="data-title">Description</span>
        <span class="data-value">{{data.card.description}}</span>
      </div>
    </div>
    <div class="data">
      <div class="data-items">
        <span class="data-title">Notes</span>
        <span class="data-value">{{data.card.notes}}</span>
      </div>
    </div>
    <div class="data" >
      <div class="data-items" *ngFor="let attributes of data.card.attributes">
        <div class="data-title">
          <img *ngIf="attributes.important" src="assets/img/star.svg" alt="important">{{attributes.title}}</div>
          <span *ngIf="!attributes.link" class="data-value">{{attributes.value}}</span>
          <a *ngIf="attributes.link" href="{{attributes.value}}" class="data-value">Tap Link</a>
      </div>
    </div>
  </div>
  <div class="modal-actions">
    <button class="btn-cancel" (click)="dialogRef.close()"></button>
  </div>
</form>
