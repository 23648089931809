<div>
  <div *ngIf="data.status === 'success' "class="message-success">{{data.type}} successfully created</div>
  <div *ngIf="data.status === 'success-edit' "class="message-success">{{data.type}} successfully edited</div>
  <div *ngIf="data === 'copy'" class="message-success">Link copied</div>
  <div *ngIf="data.status === 'error'" class="message-error">Error establishing creating a {{data.type | lowercase}}. Try again.</div>
  <div *ngIf="data.status === 'error-certificate'" class="message-error">Unfortunately there was an error creating {{data.type}}. Try again. If error persists contact Lingon support</div>
  <div *ngIf="data.status === 'block-user'" class="message-error">Administrator was blocked</div>
  <div *ngIf="data.status === 'unlock-user'" class="message-success">Administrator was unlocked</div>
  <div *ngIf="data.status === 'login-block'" class="message-error">{{data.type}}</div>
  <div *ngIf="data.status === 'error-add-photo'" class="message-error">You try to add more than 20 photos to item</div>




<!--  for import-->
  <div *ngIf="data.status === 'import' "class="message-success">File was successfully imported</div>

<!--  for assign-->
  <div *ngIf="data.status === 'successAssign' "class="message-success">User successfully assigned</div>

</div>

