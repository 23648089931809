import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {SortModel} from '../../../../models/sorts';
import {FilterTypes} from './filter-types';
import {catchError, debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {EMPTY, Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() hasSorting: boolean = true;
  @Output() sortValue: EventEmitter<SortModel> = new EventEmitter<SortModel>();
  selectedValue: SortModel;
  currentSort: any = {
    searchValue: '',
    sortValue: {
      name: 'name',
      value: 1
    }
  };
  sorts: any[];
  timeout: any = null;
  private subscription = new Subscription();
  private searchSub$ = new Subject<string>();

  ngOnInit(): void {
    this.filterType(this.type);
    if (this.hasSorting) {
      this.selectedValue = this.sorts[0].value;
    }
    this.subscribeOnSearch();
  }

  sort(event: MatSelectChange): void {
    this.currentSort.sortValue = event.value;
    this.sortValue.emit(this.currentSort);
  }

  search(event: any): void {
    this.searchSub$.next(event);
  }

  private filterType(type: string): void{
    switch (type){
      case 'brands': this.sorts = FilterTypes.Brands(); break;
      case 'models': this.sorts = FilterTypes.Models(); break;
      case 'editions': this.sorts = FilterTypes.Editions(); break;
      case 'companies': this.sorts = FilterTypes.Companies(); break;
      case 'items': this.sorts = FilterTypes.Items(); break;
    }
  }

  private subscribeOnSearch(): void {
    const createStream$ = this.searchSub$.pipe(
      map((e: any) => e.target.value),
      catchError(err => EMPTY),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(data => {
      if (data.length > 2) {
        this.currentSort.searchValue = data;
      } else {
        this.currentSort.searchValue = '';
      }
      this.sortValue.emit(this.currentSort);
    });
    this.subscription.add(createStream$);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
