export class FilterTypes {
  static Brands(): any {
    return [
      AZ, ZA, ModelsAmountPlus, ModelsAmountMinus, EditionsAmountPlus, EditionsAmountMinus, ItemsAmountPlus, ItemsAmountMinus
    ];
  }
  static Models(): any {
    return [
      AZ, ZA, EditionsAmountPlus, EditionsAmountMinus, ItemsAmountPlus, ItemsAmountMinus
    ];
  }
  static Editions(): any {
    return [
      AZ, ZA, ItemsAmountPlus, ItemsAmountMinus
    ];
  }
  static Items(): any {
    return [
      AZ, ZA
    ];
  }
  static Companies(): any {
    return [
      AZ, ZA
    ];
  }
}

const AZ = {value: {name: 'name', value: 1}, viewValue: 'Alphabetical A-Z'};
const ZA = {value: {name: 'name', value: -1}, viewValue: 'Alphabetical Z-A'};
const ModelsAmountPlus = {value: {name: 'counts.models', value: 1}, viewValue: 'Models amount', img: './assets/img/sort-Z-A.svg'};
const ModelsAmountMinus = {value: {name: 'counts.models', value: -1}, viewValue: 'Models amount', img: './assets/img/sort-A-Z.svg'};
const EditionsAmountPlus = {value: {name: 'counts.editions', value: 1}, viewValue: 'Editions amount', img: './assets/img/sort-Z-A.svg'};
const EditionsAmountMinus = {value: {name: 'counts.editions', value: -1}, viewValue: 'Editions amount', img: './assets/img/sort-A-Z.svg'};
const ItemsAmountPlus = {value: {name: 'counts.items', value: 1}, viewValue: 'Items amount', img: './assets/img/sort-Z-A.svg'};
const ItemsAmountMinus = {value: {name: 'counts.items', value: -1}, viewValue: 'Items amount', img: './assets/img/sort-A-Z.svg'};
