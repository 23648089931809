import { NgModule } from '@angular/core';
import { MainContentComponent } from './main-content.component';
import { MaterialModule } from '../modules/material.module';
import { MainModule } from '../modules/main.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AddCertificateComponent } from './private/modal-windows/add-certificate/add-certificate.component';
import { FiltersComponent } from './private/components/filters/filters.component';
import { EmptyResultComponent } from './private/components/empty-result/empty-result.component';
import { EditionPopupComponent } from './private/modal-windows/edition-popup/edition-popup.component';
@NgModule({

  declarations: [
    MainContentComponent,
    AddCertificateComponent,
    FiltersComponent,
    EditionPopupComponent,
  ],

  imports: [
    MaterialModule,
    MainModule,
    ReactiveFormsModule,
  ],

    exports: [
        MainContentComponent,
        FiltersComponent,
    ],
})
export class MainContentModule {}
