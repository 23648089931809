<div id="filter">
  <div class="search">
    <form class="search-from">
      <div class="search-field">
        <input type="text" (input)="search($event)" placeholder="Search">
      </div>
    </form>
  </div>
  <div class="sort"
       *ngIf="hasSorting">
    <span>Sort by: </span>
    <mat-form-field appearance="fill">
      <mat-select (selectionChange)="sort($event)" [(ngModel)]="selectedValue" name="sort">
        <mat-option  *ngFor="let sort of sorts" [value]="sort.value">
          <span>{{sort.viewValue}}</span>
          <img src="{{sort.img}}" alt="">
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>


