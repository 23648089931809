import {Component, Inject, OnInit} from '@angular/core';
import Swiper, {Navigation, Pagination} from 'swiper';
import {environment} from '../../../../../environments/environment';
import {IconsService} from '../../../../services/icons.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {FeathersService} from '../../../../services/feathers.service';
import {AlertMessageComponent} from '../../components/alert-message/alert-message.component';
import SwiperCore from 'swiper';
import {CreatedEditionComponent} from '../created-edition/created-edition.component';
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-edition-popup',
  templateUrl: './edition-popup.component.html',
  styleUrls: ['./edition-popup.component.scss']
})
export class EditionPopupComponent implements OnInit {
  swiper = new Swiper('.swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      currentClass: '.swiper-dots'
    },
  });
  count: number;
  defaultFilter: any = {
    skip: '',
    searchValue: '',
    sortValue: {
      name: 'name',
      value: 1
    }
  };
  thumbsSwiper: any;
  editionName: string;
  baseUrl: string = environment.url;
  sliderImages: string[] = [];
  userRole: string;

  constructor(public icons: IconsService,
              private _snackBar: MatSnackBar,
              private matDialog: MatDialog,
              public dialogRef: MatDialogRef<EditionPopupComponent>,
              private route: Router,
              private featherService: FeathersService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    const user = JSON.parse(localStorage.getItem('user'));
    this.userRole = user.administrator.permissions;
  }

  ngOnInit(): void {
    this.featherService.getImages(this.data.card.images).then(({data: files}) => {
      this.sliderImages = files.map(el => {
        return el.file.filename;
      });
    });
  }

  openEditModalDialog(event, editionCard): void{
    event.preventDefault();
    event.stopPropagation();
    this.matDialog.open(CreatedEditionComponent, {disableClose: true, width: '680px', data: {editionCard, type: 'edit'}});
  }

  copy(): void {
    this._snackBar.openFromComponent(AlertMessageComponent, {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: 'copy'
    });
  }
}
