import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})

export class FilesService {
  constructor(private http: HttpClient) {
  }

  public saveImages(data): Observable<any> {
    return this.http.post(`${environment.url}images`, data);
  }

  public deleteImage(id): Observable<any> {
    return this.http.delete(`${environment.url}images/${id}`);
  }
}
